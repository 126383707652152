import React from 'react'
import Home from "./scenes/Home.js"

function App() {
  return (
      <Home />
  );
}

export default App;
